import ChukkerItem from "./ChukkerItem";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import TeamItem from "./TeamItem";
import useIsMobileDisplay from "../membership/useIsMobileDisplay";
//<ul className={classes.list} ref={props.reference}>
function ChukkerList(props) {
  const numberOfTiles = 2; //useNumberOfChukkerTiles(1000);
  const [isMobileDisplay] = useIsMobileDisplay();
  useEffect(() => {
    console.log("Tiler");
    console.log(numberOfTiles);
  }, [numberOfTiles]);

  if (!numberOfTiles) {
    return null;
  }
  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <Grid container spacing={8}>
        <Grid size={{ xs: isMobileDisplay || (props.managerMode !==-1 && !props.betaMode) ? 12 : 8 }}>
          <Grid container spacing={1}>
            {Object.entries(props.chukkerList).map(([key, value]) => {
              if (key === "-1") {
                return null;
              }
              return (
                <Grid
                  size={{ xs: Math.floor(12 / (numberOfTiles - 1)) }}
                  key={key}
                >
                  <ChukkerItem
                    id={key}
                    managerMode={props.managerMode}
                    teams={value}
                    referenceTeam={props.referenceTeam}
                    referencePlayer={props.referencePlayer}
                    useUSPARating={props.useUSPARating}
                    proColorMapping={props.proColorMapping}
                    betaMode={props.betaMode}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {(props.managerMode === -1 || props.betaMode) && (
          <Grid
            size={{ xs: isMobileDisplay ? 12 : 4 }}
            sx={{
              background: "white",
              //position: "-webkit-sticky",
              position: "sticky",
              top: 20,
              bottom: 20,
              paddingTop: "40px",
              paddingBottom: "40px",
              zIndex: 5,
              height: 20,
            }}
          >
            <Grid container size={{ xs: 12 }}>
              <Grid size={{ xs: 12 }} key={"A_-1"}>
                <TeamItem
                  id={"A_-1"}
                  managerMode={props.managerMode}
                  players={props.chukkerList["-1"]["A_-1"]}
                  chukkerId={"-1"}
                  referencePlayer={props.referencePlayer}
                  useUSPARating={props.useUSPARating}
                  isBench={true}
                  proColorMapping={props.proColorMapping}
                  betaMode={props.betaMode}
                />
              </Grid>
            </Grid>
            {/*<ChukkerItem
          id="-1"
          managerMode={props.managerMode}
          teams={props.chukkerList["-1"]}
          referenceTeam={props.referenceTeam}
          referencePlayer={props.referencePlayer}
          useUSPARating={props.useUSPARating}
        />*/}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ChukkerList;
