import classes from "./PlayerItem.module.css";
import TeamList from "./TeamList";
import { Card, CardContent } from "@mui/material";

function ChukkerItem(props) {
  return (
    <Card
      sx={{ mx: -1 }}
      data-testid={"chukker-" + props.id}
      style={{ backgroundColor: "rgba(93, 138, 168, 0.10)" }}
    >
      <CardContent>
        <h3 className={classes.contenth}>
          {" "}
          {props.id.startsWith("-") ? "Bench" : "Chukker " + props.id}{" "}
        </h3>
        {!props.id.startsWith("-") && (
          <TeamList
            managerMode={props.managerMode}
            teamList={props.teams}
            chukkerId={props.id}
            referencePlayer={props.referencePlayer}
            useUSPARating={props.useUSPARating}
            isBench={false}
            proColorMapping={props.proColorMapping}
            betaMode={props.betaMode}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default ChukkerItem;
