import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import managerPic from "./Manager2.jpeg";
import playerPic from "./Player.jpg";
//import chukkerbookLogo from "../components/layout/Asset1.png";
import generateMembership from "../components/membership/generateMembership";
import { useEffect, useState } from "react";
import SnackError from "../components/Signups/SnackError";
import generateManagerMembership from "../components/membership/generateManagerMembership";
function MembershipSelectionPage({
  devPort,
  currentMembership,
  setCurrentMembership,
  userName,
  setUserName,
}) {
  let navigate = useNavigate();
  //const [wordpressUserName, setWordpressUserName] = useState("");
  const [stripeEmail, setStripeEmail] = useState("");
  const [stripeName, setStripeName] = useState("");
  /*function map_membership(managerMode, wordpress_id) {
    var fetchUserName =
      devPort +
      "system_map_membership/" +
      userName +
      "/None/True/" +
      wordpress_id;
    if (managerMode !== -1) {
      fetchUserName =
        devPort +
        "system_map_membership/" +
        userName +
        "/True/None/" +
        wordpress_id;
    }
    console.log(fetchUserName);
    fetch(fetchUserName)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("User ID has been set");
      });
  }

  function retriveWordpressUserId(managerMode) {
    var fetchUserId = devPort + "system_get_manager/" + wordpressUserName;
    if (managerMode !== -1) {
      fetchUserId = devPort + "system_get_player/" + wordpressUserName;
    }
    console.log(fetchUserId);
    fetch(fetchUserId)
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data[0][0] !== null) {
          map_membership(managerMode, data[0][0]);
          console.log("User ID " + data[0][0]);
          if (managerMode === -1) {
            console.log("Manager Found");
            setCurrentMembership("manager");
            navigate("/club");
          } else {
            console.log("Player Found");
            setCurrentMembership("player");
            navigate("/playerclubselection");
          }
        } else {
          if (managerMode === -1) {
            console.log("No manager found");
            console.log("Looking for player");
            retriveWordpressUserId(0);
          } else {
            console.log("No player found");
            setAlertMessage(true);
          }
        }
      });
  }*/
  /*useEffect(()=>{
    if(wordpressUserName){
      retriveWordpressUserId(-1);
    }
  },[wordpressUserName])*/
  const [alterMessage, setAlertMessage] = useState(false);
  useEffect(() => {
    if (userName && currentMembership === "manager") {
      navigate("/club");
    }
  }, [userName, currentMembership, navigate]);
  return (
    <Layout managerMode={-2} devPort={devPort}>
      <SnackError
        alterMessage={alterMessage}
        setAlertMessage={setAlertMessage}
        snackMessage="This username was not found"
      />
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <h1> Select your Membership</h1>
            {/*<Paper elevation={24}>
              <Box textAlign="center">
                <img
                  src={chukkerbookLogo}
                  alt="Chukkerbook Logo"
                  width={353}
                  height={300}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                  }}
                />
                <Typography variant="h5" component="div">
                  Import User Account
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  If you are already a Chukkerbook user, specify your previous
                  username to import your account
                </Typography>
                <Stack spacing={2}>
                  <TextField
                    id="wordpress-account"
                    label="Chukkerbook old username"
                    variant="outlined"
                    onChange={(event) =>
                      setWordpressUserName(event.target.value)
                    }
                  />
                  <Button
                    onClick={() => {
                      console.log(wordpressUserName);
                      retriveWordpressUserId(-1);
                    }}
                    color="success"
                    variant="contained"
                    size="100%"
                    disabled={currentMembership === "manager"}
                  >
                    Import Account
                  </Button>
                </Stack>
              </Box>
            </Paper>*/}
            <Paper elevation={24}>
              <Box textAlign="center">
                <img
                  src={managerPic}
                  alt="Manager Horses"
                  width={353}
                  height={300}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                  }}
                />
                <Typography variant="h5" component="div">
                  Manager Membership
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Select this membership if you are a club manager. You will be
                  be able to create your club, manage your games and let players
                  sign up
                </Typography>
                <Stack spacing={2}>
                  <TextField
                    id="stripe-email-address"
                    label="e-mail address used to access your Stripe account"
                    variant="outlined"
                    onChange={(event) => setStripeEmail(event.target.value)}
                  />
                  <TextField
                    id="stripe-name"
                    label="First and Last name that will be displayed on your stripe account"
                    variant="outlined"
                    onChange={(event) => setStripeName(event.target.value)}
                  />
                  <Button
                    onClick={() => {
                      generateManagerMembership(
                        devPort,
                        userName,
                        stripeEmail,
                        stripeName,
                        setCurrentMembership,
                        setUserName
                      );
                      //navigate("/managersubscription");
                    }}
                    color="success"
                    variant="contained"
                    size="100%"
                    disabled={currentMembership === "manager"}
                  >
                    Manager Membership
                  </Button>
                </Stack>
              </Box>
            </Paper>
            <Paper elevation={24}>
              <Box textAlign="center">
                <img
                  src={playerPic}
                  alt="Megg on Bitcoin"
                  width={353}
                  height={300}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                  }}
                />
                <Typography variant="h5" component="div">
                  Player Membership
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Select this membership if you are a club player. You will be
                  be able to join a club, register for games and check the
                  schedules
                </Typography>
                <Stack>
                  <Button
                    onClick={() => {
                      generateMembership(devPort, userName, 0);
                      navigate("/playerclubselection");
                    }}
                    color="success"
                    variant="contained"
                    size="100%"
                    disabled={currentMembership === "player"}
                  >
                    Player Membership
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default MembershipSelectionPage;
