import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";

import PlayerList from "../components/PlayerManagement/PlayerList";
import useRefresh from "../components/Refresh/refresh";
import DashboardTabs from "../components/ui/DashboardTabs";
import getProMapping from "../components/Signups/getProMapping";
import PRO_COLORS from "./proColors";

function SignupPage({
  eventId,
  clubId,
  gameDate,
  devPort,
  waiverNotifications,
  managerMode,
  setAvailableHorseForLease,
}) {
  const [players, setPlayers] = useState([]);
  const [loadingSignups, setLoadingSignups] = useState(true);
  const [proColorMapping, setProColorMapping] = useState();
  const [totalNumberLeasedChukkers, setTotalNumberLeasedChukkers] = useState(0);
  const [
    totalNumberLeasedChukkersAvailable,
    setTotalNumberLeasedChukkersAvailable,
  ] = useState(0);
  let navigate = useNavigate();
  useRefresh(clubId, devPort);
  console.log("Signup Event" + eventId);
  const loadPlayers = useCallback(() => {
    console.log(devPort + "player_roster/" + clubId + "/" + eventId);
    fetch(devPort + "player_roster/" + clubId + "/" + eventId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const newProColorMapping = {};
        let colorIndex = 0;
        let formattedData = [];
        let totalLeasedChukkers = 0;
        data.map((player) => {
          if (player[11]) {
            totalLeasedChukkers += player[11];
          }
          formattedData.push({
            player_id: player[0],
            first_name: player[1],
            last_name: player[2],
            uspa_rating: player[3],
            club_rating: player[4],
            count_chukkers_requested: player[5],
            game_id: player[6],
            selected_pro_id: player[7],
            is_club_pro: player[8],
            signup_notes: player[9],
            paid_lease: player[10],
            count_chukkers_leased: player[11],
          });
          if (
            (player[7] && !newProColorMapping[player[7]]) ||
            (player[8] && !newProColorMapping[player[0]])
          ) {
            const player_index_mapping = { 7: 7, 8: 0 };
            Object.keys(player_index_mapping).map((index) => {
              if (
                player[index] &&
                !newProColorMapping[player[player_index_mapping[index]]]
              ) {
                newProColorMapping[player[player_index_mapping[index]]] =
                  PRO_COLORS[colorIndex];
                if (colorIndex === PRO_COLORS.length - 1) {
                  colorIndex = 0;
                } else {
                  colorIndex += 1;
                }
              }
              return null;
            });
          }
          return null;
        });
        setProColorMapping(newProColorMapping);
        console.log(newProColorMapping);
        setPlayers(formattedData);
        setTotalNumberLeasedChukkers(totalLeasedChukkers);
        console.log("FOMRATTER");
        console.log(formattedData);
        setLoadingSignups(false);
      });
  }, [devPort, clubId, eventId]);
  
  const [proMapping, setProMapping] = useState();
  useEffect(() => {
    loadPlayers();
    const player_fetcher = devPort + "player_list/" + clubId + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setProMapping,
      () => {},
      () => {} //setLoadingClubPros
    );
  }, [devPort, clubId, loadPlayers]);
  function deletePlayer(playerId) {
    fetch(devPort + "delete_signup/" + eventId + "/" + playerId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        loadPlayers();
      });
  }
  let currentPage = "/signup";
  if (managerMode !== -1) {
    currentPage = "/playersignup";
  }
  //if (loadingSignups) {
  //    return <div>Loading Signups ...</div>
  //}
  useEffect(() => {
    console.log(devPort + "get_horse_chukker_game/" + eventId);
    fetch(devPort + "get_horse_chukker_game/" + eventId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0]) {
          setTotalNumberLeasedChukkersAvailable(data[0][0]);
        }
      });
  }, [devPort, eventId]);

  useEffect(() => {
    setAvailableHorseForLease(
      totalNumberLeasedChukkersAvailable - totalNumberLeasedChukkers
    );
  }, [
    totalNumberLeasedChukkersAvailable,
    totalNumberLeasedChukkers,
    setAvailableHorseForLease,
  ]);
  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications} devPort={devPort}>
      <DashboardTabs
        currentPage={currentPage}
        managerMode={managerMode}
        gameMode="game"
      />
      <section>
        <PlayerList
          devPort={devPort}
          loadingPage={loadingSignups}
          managerMode={managerMode}
          title={"Signups for Game on " + gameDate}
          clubId={clubId}
          gameId={eventId}
          players={players}
          nameEdit={false}
          ratingEdit={false}
          chukkerEdit={true}
          refresh={loadPlayers}
          playerDelete={deletePlayer}
          addPlayer={() => navigate("/addplayer")}
          proColorMapping={proColorMapping}
          tableMode="games"
          proMapping={proMapping}
          totalNumberLeasedChukkers={totalNumberLeasedChukkers}
          totalNumberLeasedChukkersAvailable={
            totalNumberLeasedChukkersAvailable
          }
        />
      </section>
    </Layout>
  );
}

export default SignupPage;
