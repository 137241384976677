import { Stack } from "@mui/material";
import PlayerItem from "./PlayerItem";
import EmptyCard from "./EmptyCard";
function PlayerList(props) {
  if (Object.entries(props.playerList).length === 0) {
    return <EmptyCard />;
  }
  return (
    <Stack spacing={1}>
      {Object.entries(props.playerList).map(([key, players]) => {
        return (
          <PlayerItem
            id={key}
            key={key}
            managerMode={props.managerMode}
            index={players[3]}
            firstName={players[4]}
            lastName={players[5]}
            rating={props.useUSPARating ? players[6] : players[7]}
            chukker={props.chukker}
            team={props.team}
            isBench={props.isBench}
            proColorMapping={props.proColorMapping}
            betaMode={props.betaMode}
          />
        );
      })}
    </Stack>
  );
}

export default PlayerList;
