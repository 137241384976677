import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
function ConfirmationModal({
  confirmationModal,
  setConfirmationModal,
  confirmationString,
  onApproval,
}: {
  confirmationModal: boolean;
  setConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirmationString: string;
  onApproval: () => void;
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={confirmationModal}
      onClose={() => {
        setConfirmationModal(false);
      }}
      aria-labelledby={"Confirmation " + confirmationString}
      aria-describedby={"Confirmation " + confirmationString}
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography id="modal-prompt" variant="h6" component="h2">
            {confirmationString}
          </Typography>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  onApproval();
                  setConfirmationModal(false);
                }}
                style={{ width: "100%" }}
              >
                Yes
              </Button>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setConfirmationModal(false);
                }}
                style={{ width: "100%" }}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ConfirmationModal;
