import { Button, Modal, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/material/Grid2";
function EmailBodyModal({
  navigateModal,
  setNavigateModal,
  emailBody,
  setEmailBody,
  emailMembers,
}:{
  navigateModal:boolean,
  setNavigateModal: React.Dispatch<React.SetStateAction<boolean>>,
  emailBody: string,
  setEmailBody: React.Dispatch<React.SetStateAction<string>>,
  emailMembers: any,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={navigateModal}
      onClose={() => {
        setNavigateModal(false);
      }}
      aria-labelledby="no-mailing-list"
      aria-describedby="No mailing list"
      data-testid="no-mailing-list-modal"
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography variant="h4">
            Enter any notes you want to include in the body of the email
          </Typography>
          <Typography variant="body1">
            Leave this blank if you do not want to include any notes
          </Typography>
          <TextField
            id="email-body"
            label="Enter notes you want to add"
            data-testid="email-body"
            value={emailBody}
            multiline
            rows={4}
            style={{ width: "100%" }}
            onChange={(event) => setEmailBody(event.target.value)}
            //defaultValue="Default Value"
          />
          <Grid container spacing={2}>
            <Grid size={{ xs: 6 }}>
              <Button
                variant="contained"
                color="primary"
                data-testid="email-members"
                style={{ width: "100%" }}
                onClick={() => {
                  setNavigateModal(false);
                  emailMembers();
                }}
              >
                Send Email
              </Button>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Button
                variant="outlined"
                color="error"
                data-testid="save-modal-no"
                style={{ width: "100%" }}
                onClick={() => {
                  setNavigateModal(false);
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
}

export default EmailBodyModal;
