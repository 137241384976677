import classes from "./PlayerItem.module.css";
import PlayerList from "./PlayerList";
import { useEffect, useState } from "react";
import { Card, CardContent, LinearProgress, Paper } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { chukkerbookThemeTransparent } from "../../chukkerbbokTheme";

function TeamItem(props) {
  const [handicap, setHandicap] = useState(0);
  const [isComputingHandicap, setIsComputingHandicap] = useState(true);
  const numberPlayers = Object.keys(props.players).length;
  useEffect(() => {
    var newHandicap = 0;
    console.log("Recompute Handicap");
    Object.keys(props.players).forEach((key) => {
      if (props.useUSPARating) {
        newHandicap += props.players[key][6];
      } else {
        newHandicap += props.players[key][7];
      }
    });
    setHandicap(newHandicap);
    setIsComputingHandicap(false);
  }, [props.players, props.useUSPARating, numberPlayers]);

  return (
    <Paper elevation={0}>
      <Card  sx={{ mx: -2 }} style={{ backgroundColor: props.isBench ? chukkerbookThemeTransparent["secondary"] : props.id.split("_")[0] === "A" ? chukkerbookThemeTransparent["warning"] : chukkerbookThemeTransparent["success"] }}>
        <CardContent>
          <Droppable
            droppableId={props.chukkerId + "____" + props.id}
            type="CHUKKER"
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable
                  draggableId={props.id}
                  isDragDisabled={props.managerMode !== -1 || true}
                  index={parseInt(
                    props.id.split("_")[1] === "A"
                      ? props.id.split("_")[1]
                      : 100 * props.id.split("_")[1]
                  )}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Droppable
                        droppableId={props.chukkerId + "____" + props.id}
                        type="TEAM"
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {!isComputingHandicap && (
                              <div
                                className={classes.content}
                                data-testid={"handicap-" + props.id} //ref={dropPlayer}
                              >
                                {" "}
                                {props.id === "A_-1"
                                  ? "Bench"
                                  : "Team " + props.id.split("_")[0]}{" "}
                                {(props.managerMode !== -1 && !props.betaMode) ? "" : "Handicap " + handicap}
                                <PlayerList
                                  managerMode={props.managerMode}
                                  playerList={props.players}
                                  team={props.id}
                                  chukker={props.chukkerId}
                                  useUSPARating={props.useUSPARating}
                                  isBench={props.isBench}
                                  proColorMapping={props.proColorMapping}
                                  betaMode={props.betaMode}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                            {isComputingHandicap && (
                              <LinearProgress color="secondary" />
                            )}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default TeamItem;
