import { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import ChukkerList from "../components/Chukkers/ChukkerList";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import classes from "../components/Chukkers/PlayerItem.module.css";
import DashboardTabs from "../components/ui/DashboardTabs";
import Layout from "../components/layout/Layout";
import MatchupTable from "../components/Chukkers/MatchupTable";
import useRefresh from "../components/Refresh/refresh";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SavePromptModal from "../components/ui/SavePromptModal";
import EmailIcon from "@mui/icons-material/Email";
import PRO_COLORS from "./proColors";
import getProMapping from "../components/Signups/getProMapping";
import NoEmailModal from "../components/Chukkers/NoEmailModal";
import EmailBodyModal from "../components/Chukkers/EmailBodyModal";

//<PlayerList chukkerList={DUMMY_DATA} />

function MatchupPage({
  eventId,
  clubId,
  gameDate,
  devPort,
  waiverNotifications,
  managerMode,
  gamePoloType,
  betaMode
}) {
  useRefresh(clubId, devPort);
  const [listOfChukkers, setListofChukkers] = useState();
  const [listOfChukkersReference, setListofChukkersReference] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [useUSPARating, setUseUSPARating] = useState(false);
  const [noMatchup, setNoMatchup] = useState(false);
  const [spreadsheetView, setSpreadsheetView] = useState(false);
  const [playerSchedule, setPlayerSchedule] = useState();
  const [outputSchedule, setOutputSchedule] = useState();
  const [chukkerNumber, setChukkerNumber] = useState(0);
  const [idMapping, setIdMapping] = useState();
  const [spreadsheetBench, setSpreadsheetBench] = useState([]);
  const [matchupAlgo, setMatchupAlgo] = useState("PRO");
  const [advancedSettings, setAdvancedSettings] = useState(false);

  const [proMapping, setProMapping] = useState();
  const [mailingList, setMailingList] = useState("");
  const getMailingList = useCallback(() => {
    const fetch_mailing_list = devPort + "club_mailing_list/" + clubId;
    console.log(fetch_mailing_list);
    fetch(fetch_mailing_list)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Mailing List");
        if (data && data[0]) {
          console.log(data[0][0]);
          setMailingList(data[0][0]);
        }
      });
  }, [devPort, clubId]);

  useEffect(() => {
    const player_fetcher = devPort + "player_list/" + clubId + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setProMapping,
      () => {},
      () => {} //setLoadingClubPros
    );
    getMailingList();
  }, [devPort, clubId, getMailingList]);
  const [proColorMapping, setProColorMapping] = useState();
  const loadPlayers = useCallback(() => {
    console.log(devPort + "player_roster/" + clubId + "/" + eventId);
    fetch(devPort + "player_roster/" + clubId + "/" + eventId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const newProColorMapping = {};
        const newPlayerPros = {};
        let colorIndex = 0;
        data.map((player) => {
          newPlayerPros[player[0]] = player[7];
          if (
            (player[7] && !newProColorMapping[player[7]]) ||
            (player[8] && !newProColorMapping[player[0]])
          ) {
            const player_index_mapping = { 7: 7, 8: 0 };
            Object.keys(player_index_mapping).map((index) => {
              if (
                player[index] &&
                !newProColorMapping[player[player_index_mapping[index]]]
              ) {
                newProColorMapping[player[player_index_mapping[index]]] =
                  PRO_COLORS[colorIndex];
                if (colorIndex === PRO_COLORS.length - 1) {
                  colorIndex = 0;
                } else {
                  colorIndex += 1;
                }
              }
              return null;
            });
          }
          return null;
        });
        setProColorMapping({
          PROS: newProColorMapping,
          PLAYERS: newPlayerPros,
          MAPPING: proMapping,
        });
        console.log("CHECK PRO COLOR MAPPING");
        console.log(newProColorMapping);
      });
  }, [devPort, clubId, eventId, proMapping]);

  useEffect(() => {
    if (proMapping) {
      loadPlayers();
    }
  }, [proMapping, loadPlayers]);

  const getMatchupFromApi = useCallback(
    (matchup, matchupAlgo, useUSPARating) => {
      setIsLoading(true);
      let matchup_call = devPort + matchup + "/";
      if (matchup === "load_saved_matchup") {
        matchup_call = matchup_call + eventId;
      } else {
        const pullRating = useUSPARating ? "uspa" : "club";
        matchup_call =
          matchup_call +
          clubId +
          "/" +
          eventId +
          "/" +
          gameDate +
          "/" +
          pullRating +
          "/" +
          matchupAlgo +
          "/" +
          gamePoloType;
      }

      console.log(matchup_call);
      fetch(matchup_call)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data[0] && Object.keys(data[0]).length !== 0) {
            console.log("GENERATE MATCHUP IS");
            console.log(data[0]);
            //Does not do deep copy
            let newSchedule = data[0];
            let newSpreadsheetBench = [];
            if (-1 in data[0]) {
              Object.keys(data[0]).forEach((keys) => {
                if (keys < -1) {
                  console.log("MY KEYS");
                  console.log(keys);
                  console.log(
                    Object.keys(data[0][keys]["A_" + keys.toString()])
                  );
                  Object.keys(data[0][keys]["A_" + keys.toString()]).forEach(
                    (playerNumber) => {
                      newSchedule[-1]["A_-1"][playerNumber] =
                        data[0][keys]["A_" + keys.toString()][playerNumber];
                    }
                  );
                  delete newSchedule[keys];
                }
              });
              Object.keys(data[0][-1]["A_-1"]).forEach((playerNumber) => {
                const playerSave = {
                  game_id: eventId,
                  chukker_id: data[0][-1]["A_-1"][playerNumber][1],
                  team_id: "A", //listOfChukkers[chukkerId][team][playerId][2],
                  player_id: data[0][-1]["A_-1"][playerNumber][3].toString(),
                };
                newSpreadsheetBench.push(playerSave);
              });
            } else {
              newSchedule[-1] = {};
              newSchedule[-1]["A_-1"] = {};
            }
            console.log("CURRENT BENCH");
            console.log(newSpreadsheetBench);
            setSpreadsheetBench(newSpreadsheetBench);
            setListofChukkers(data[0]);
            setPlayerSchedule(data[1]);
            setOutputSchedule(data[2]);
            setChukkerNumber(data[3]);
            setIdMapping(data[4]);

            if (matchup === "load_saved_matchup") {
              setListofChukkersReference(data[0]);
            }
            setIsLoading(false);
          } else {
            if (matchup === "load_saved_matchup") {
              if (managerMode === -1) {
                console.log(
                  "No matchup found in the DB. Generating matchup..."
                );
                getMatchupFromApi("generate_matchup_strategy", matchupAlgo, useUSPARating);
              } else {
                console.log("No matchup found in the DB");
                setNoMatchup(true);
                setIsLoading(false);
              }
            } else {
              console.log("No matchup available");
              setNoMatchup(true);
              setIsLoading(false);
            }
          }
        });
    },
    [
      devPort,
      eventId,
      gameDate,
      managerMode,
      clubId,
      gamePoloType,
    ]
  );
  useEffect(() => {
    getMatchupFromApi("load_saved_matchup");
  }, [spreadsheetView, getMatchupFromApi]);

  function moveTeam(id_sender, chukker_sender, id_receiver) {
    console.log(id_sender);
    console.log(id_receiver);
    console.log(chukker_sender);
    var newChukkerList = { ...listOfChukkers };
    console.log(newChukkerList);
    const movingTeam = newChukkerList[chukker_sender][id_sender];
    delete newChukkerList[chukker_sender][id_sender];
    newChukkerList[id_receiver][id_sender] = movingTeam;
    setListofChukkers(newChukkerList);
  }
  function swapTeam(chukker_sender, id_sender, chukker_receiver, id_receiver) {
    console.log(id_sender);
    console.log(id_receiver);
    console.log(chukker_sender);
    var newChukkerList = { ...listOfChukkers };
    console.log(newChukkerList);
    const movingTeamFrom = newChukkerList[chukker_sender][id_sender];
    const movingTeamTo = newChukkerList[chukker_receiver][id_receiver];
    delete newChukkerList[chukker_sender][id_sender];
    delete newChukkerList[chukker_receiver][id_receiver];
    //newChukkerList[chukker_receiver]["teams"][id_sender] = movingTeamFrom;
    //newChukkerList[chukker_sender]["teams"][id_receiver] = movingTeamTo;
    newChukkerList[chukker_sender][id_sender] = movingTeamTo;
    newChukkerList[chukker_receiver][id_receiver] = movingTeamFrom;
    setListofChukkers(newChukkerList);
  }

  function movePlayer(
    id_sender,
    chukker_sender,
    team_sender,
    id_receiver,
    chukker_receiver
  ) {
    console.log(id_sender);
    console.log(id_receiver);
    console.log(chukker_sender);
    console.log(team_sender);
    const newChukkerList = { ...listOfChukkers };
    const movingPlayer = newChukkerList[chukker_sender][team_sender][id_sender];
    delete newChukkerList[chukker_sender][team_sender][id_sender];
    newChukkerList[chukker_receiver][id_receiver][id_sender] = movingPlayer;
    console.log(newChukkerList);
    setListofChukkers(newChukkerList);
  }
  console.log(listOfChukkers);
  //TODO dnd messes up the new chukkers
  function createChukker() {
    const newChukkerList = { ...listOfChukkers };
    //const chukkerNumber = Object.keys(newChukkerList).length;
    console.log(chukkerNumber);
    newChukkerList[chukkerNumber + 1] = {};
    newChukkerList[chukkerNumber + 1] = {};
    newChukkerList[chukkerNumber + 1]["A_" + (chukkerNumber + 1)] = {};
    newChukkerList[chukkerNumber + 1]["B_" + (chukkerNumber + 1)] = {};
    setChukkerNumber(chukkerNumber + 1);
    setListofChukkers(newChukkerList);
  }
  function deleteChukker() {
    const newChukkerList = { ...listOfChukkers };
    //-1 to exclude the bench
    const chukkerNumber = Object.keys(newChukkerList).length-1;
    delete newChukkerList[chukkerNumber];
    setChukkerNumber(chukkerNumber - 1);
    setListofChukkers(newChukkerList);

  }
  function moveSpreadSheet(playerName, team, sourceIndex, destinationIndex) {
    if (playerSchedule[team][playerName][destinationIndex] !== "x") {
      console.log(playerSchedule[team][playerName]);
      console.log(playerSchedule[team][playerName][sourceIndex]);
      console.log(playerSchedule[team][playerName][destinationIndex]);
      playerSchedule[team][playerName][destinationIndex] = "x";
      playerSchedule[team][playerName][sourceIndex] = "";
    }
  }
  function moveSpreadSheetPlayer(playerName, teamOrigin, teamDestination) {
    const player = playerSchedule[teamOrigin][playerName];
    delete playerSchedule[teamOrigin][playerName];
    if (playerName in playerSchedule[teamDestination]) {
      console.log("CONFUSED");
      console.log(player);
      let index = 0;
      let newPlayer = playerSchedule[teamDestination][playerName];
      while (index < player.length) {
        if (player[index] === "x") {
          newPlayer[index] = "x";
        }
        index += 1;
      }
      playerSchedule[teamDestination][playerName] = newPlayer;
    } else {
      playerSchedule[teamDestination][playerName] = player;
    }
  }

  function dropData(result) {
    console.log(result);
    console.log(result.type);
    console.log(result.source);
    console.log(result.destination);
    const sender_info = result.source.droppableId.split("____");
    const receiver_info = result.destination.droppableId.split("____");
    if (result.type === "TEAM") {
      movePlayer(
        result.draggableId,
        sender_info[0],
        sender_info[1],
        receiver_info[1],
        receiver_info[0]
      );
    }
    if (result.type === "CHUKKER") {
      swapTeam(
        sender_info[0],
        sender_info[1],
        receiver_info[0],
        receiver_info[1]
      );
    }

    if (result.type.startsWith("SPREADSHEET_X")) {
      if (result.source.droppableId === result.destination.droppableId) {
        moveSpreadSheet(
          sender_info[1],
          sender_info[0],
          result.source.index,
          result.destination.index
        );
      }
    }
    if (result.type === "SPREADSHEET_TEAM_SWAP") {
      console.log("HELLO");
      console.log(result.draggableId.split("____")[1]);
      console.log(sender_info[0]);
      console.log(receiver_info[0]);
      moveSpreadSheetPlayer(
        result.draggableId.split("____")[1],
        sender_info[0],
        receiver_info[0]
      );
    }
  }
  let currentPage = "/matchup";
  if (managerMode !== -1) {
    currentPage = "/playermatchup";
  }
  useRefresh(clubId, devPort);
  function saveToDb() {
    console.log("Saving to Database...");
    console.log(spreadsheetBench);
    let playerSaveList = [];
    if (spreadsheetView) {
      Object.keys(playerSchedule).map((teamId) => {
        Object.keys(playerSchedule[teamId]).map((playerId) => {
          playerSchedule[teamId][playerId].map((chukker, index) => {
            if (chukker === "x") {
              const playerSave = {
                game_id: eventId,
                chukker_id: index + 1,
                team_id: teamId, //listOfChukkers[chukkerId][team][playerId][2],
                player_id: playerId,
              };
              console.log(playerSave);
              playerSaveList.push(playerSave);
            }
            return null;
          });
          return null;
        });
        playerSaveList = playerSaveList.concat(spreadsheetBench);
        return null;
      });
    } else {
      let playerBenchMapping = {};
      Object.keys(listOfChukkers).map((chukkerId) => {
        let playerBenchMappingCurrentChukker = {};
        Object.keys(listOfChukkers[chukkerId]).map((team) => {
          Object.keys(listOfChukkers[chukkerId][team]).map((playerId) => {
            let newChukkerId = chukkerId;
            let newTeamId = team.split("_")[0];
            console.log(chukkerId);
            console.log(listOfChukkers[chukkerId][team][playerId]);
            //Check for all chukkerId to avoid deletion if duplicate player in random chukker
            if (
              listOfChukkers[chukkerId][team][playerId][3] in
                playerBenchMappingCurrentChukker ||
              chukkerId === "-1"
            ) {
              newTeamId = "A";
              if (
                listOfChukkers[chukkerId][team][playerId][3] in
                playerBenchMapping
              ) {
                newChukkerId =
                  playerBenchMapping[
                    listOfChukkers[chukkerId][team][playerId][3]
                  ];
                playerBenchMapping[
                  listOfChukkers[chukkerId][team][playerId][3]
                ] -= 1;
              } else {
                newChukkerId = -1;
                playerBenchMapping[
                  listOfChukkers[chukkerId][team][playerId][3]
                ] = -2;
              }
            } else {
              playerBenchMappingCurrentChukker[
                listOfChukkers[chukkerId][team][playerId][3]
              ] = true;
            }
            console.log(newChukkerId);
            const playerSave = {
              game_id: eventId,
              chukker_id: newChukkerId,
              team_id: newTeamId, //listOfChukkers[chukkerId][team][playerId][2],
              player_id: listOfChukkers[chukkerId][team][playerId][3],
            };
            playerSaveList.push(playerSave);
            //console.log(playerSave);
            //console.log(listOfChukkers[chukkerId][team][playerId]);
            return null;
          });
          return null;
        });
        return null;
      });
    }

    const saveCall = devPort + "store_daily_games/" + eventId;
    console.log(saveCall);
    console.log(playerSaveList);
    /*const text = {
      operation:"insert"
  }*/
    fetch(saveCall, {
      method: "POST",
      body: JSON.stringify(playerSaveList),
      headers: {
        "Content-Type": "application/json",
      },
      /*, 
      {
        method:'POST',
        body: JSON.stringify({"TEST":1}),
        headers: {
          'Content-Type': 'application/json'
        }*/
    }).then((response) => {
      console.log("Matchup Saved to DB");
      getMatchupFromApi("load_saved_matchup");
      return response.json();
    });
    console.log("Switching View");
    setSpreadsheetView(true);
  }
  function goToSpreadsheet() {
    setSpreadsheetView(!spreadsheetView);
  }
  const [navigateModal, setNavigateModal] = useState(false);
  function emailMembersWrapper(){
    if (!mailingList) {
      setNoEmailModal(true);
    } else {
      setEmailBodyModal(true);
    }
  }
  function emailMembers() {
    const emailCall = devPort + "email_daily_games";
    console.log(emailCall);
    fetch(emailCall, {
      method: "POST",
      body: JSON.stringify({
        clubId: clubId,
        schedule: playerSchedule,
        mapping: idMapping,
        chukkerNumber: chukkerNumber,
        emailBody: emailBody,
        gameDate: gameDate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log("Email Sent");
      return response.json;
    });
  }
  const [noEmailModal, setNoEmailModal] = useState(false);
  const [emailBodyModal, setEmailBodyModal] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  return (
    <Layout
      managerMode={managerMode}
      waiverNotifications={waiverNotifications}
      devPort={devPort}
    >
      <DashboardTabs
        currentPage={currentPage}
        managerMode={managerMode}
        toReload={listOfChukkers !== listOfChukkersReference}
        saveToDb={saveToDb}
        gameMode="game"
      />
      <SavePromptModal
        navigateModal={navigateModal}
        setNavigateModal={setNavigateModal}
        navigateModalFunc={goToSpreadsheet}
        saveToDb={saveToDb}
      />
      <NoEmailModal
        navigateModal={noEmailModal}
        setNavigateModal={setNoEmailModal}
      />
      <EmailBodyModal
        navigateModal={emailBodyModal}
        setNavigateModal={setEmailBodyModal}
        emailBody={emailBody}
        setEmailBody={setEmailBody}
        emailMembers={emailMembers}
      />
      <DragDropContext onDragEnd={dropData}>
        <Stack spacing={2}>
          <Card data-testid="matchup-card">
            <CardContent>
              <h1 className={classes.contenth}>Game Matchup </h1>
              <Stack spacing={2}>
                <ToggleButtonGroup
                  color="secondary"
                  value={spreadsheetView ? "spreadsheet" : "chukker"}
                  exclusive
                  fullWidth={true}
                  aria-label="View"
                >
                  <ToggleButton
                    value="chukker"
                    data-testid="switch-view-chukker"
                    onClick={() => setSpreadsheetView(false)}
                  >
                    Chukker View
                  </ToggleButton>
                  <ToggleButton
                    value="spreadsheet"
                    data-testid="switch-view-spreadsheet"
                    onClick={() => {
                      if (listOfChukkers !== listOfChukkersReference) {
                        setNavigateModal(true);
                      } else {
                        setSpreadsheetView(true);
                      }
                    }}
                  >
                    Spreadsheet View
                  </ToggleButton>
                </ToggleButtonGroup>
                {managerMode === -1 && (
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 4 }}>
                      <Button
                        variant="outlined"
                        data-testid="save-chukkers"
                        style={{ width: "100%" }}
                        onClick={saveToDb}
                      >
                        <SaveIcon />
                        Save
                      </Button>
                    </Grid>
                    <Grid size={{ xs: 4 }}>
                      <Button
                        variant="outlined"
                        data-testid="export-chukkers"
                        style={{ width: "100%" }}
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          outputSchedule //JSON.stringify(playerSchedule)
                        )}`}
                        download="matchup.csv"
                      >
                        <DownloadIcon />
                        Export
                      </Button>
                    </Grid>
                    <Grid size={{ xs: 4 }}>
                      <Button
                        variant="outlined"
                        data-testid="email-chukkers"
                        style={{ width: "100%" }}
                        onClick={emailMembersWrapper}
                      >
                        <EmailIcon />
                        Email
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {!spreadsheetView && managerMode ===-1 &&  (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={advancedSettings}
                        data-testid="advanced-settings"
                        onChange={() => setAdvancedSettings(!advancedSettings)}
                      />
                    }
                    label="Advanced Settings"
                  />
                )}
                {!spreadsheetView && managerMode === -1 && advancedSettings && (
                  <ToggleButtonGroup
                    color="secondary"
                    value={useUSPARating ? "uspa" : "club"}
                    exclusive
                    fullWidth={true}
                    aria-label="View"
                  >
                    <ToggleButton
                      value="club"
                      data-testid="switch-rating-club"
                      onClick={() => setUseUSPARating(false)}
                    >
                      Club Rating
                    </ToggleButton>
                    <ToggleButton
                      value="uspa"
                      data-testid="switch-rating-uspa"
                      onClick={() => setUseUSPARating(true)}
                    >
                      USPA Rating
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {!spreadsheetView && managerMode === -1 && advancedSettings && (
                  <FormControl>
                    <FormLabel id="matchup-algo">
                      Matchup Generation Preference
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="matchup-algo"
                      name="row-balanced-pro"
                      value={matchupAlgo}
                      onChange={(event) => setMatchupAlgo(event.target.value)}
                    >
                      <FormControlLabel
                        value="BALANCED"
                        control={<Radio />}
                        label="Balanced Teams"
                      />
                      <FormControlLabel
                        value="PRO"
                        control={<Radio />}
                        label="Fulfill Pro Requests"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {!spreadsheetView && (managerMode === -1 || betaMode) && (
                  <Button
                    variant="outlined"
                    data-testid="refresh-chukkers"
                    onClick={() => {
                      getMatchupFromApi(
                        "generate_matchup_strategy",
                        matchupAlgo,
                        useUSPARating
                      );
                    }}
                  >
                    <SyncIcon />
                    Regenerate Matchup
                  </Button>
                )}
                {!isLoading && !noMatchup && !spreadsheetView && (
                  <ChukkerList
                    managerMode={managerMode}
                    chukkerList={listOfChukkers}
                    referenceTeam={moveTeam}
                    referencePlayer={movePlayer}
                    useUSPARating={useUSPARating}
                    proColorMapping={proColorMapping}
                    betaMode={betaMode}
                  />
                )}
                {!isLoading &&
                  !noMatchup &&
                  !spreadsheetView &&
                  managerMode === -1 && (
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        data-testid="add-chukker"
                        onClick={createChukker}
                      >
                        <AddIcon />
                        Add Chukker
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        data-testid="delete-chukker"
                        onClick={deleteChukker}
                      >
                        <RemoveIcon />
                        Delete Chukker
                      </Button>
                    </Stack>
                  )}

                {!isLoading && !noMatchup && spreadsheetView && (
                  <MatchupTable
                    playerList={playerSchedule}
                    chukkerNumber={chukkerNumber}
                    managerMode={managerMode}
                    idMapping={idMapping}
                  />
                )}
                {isLoading && <LinearProgress color="secondary" />}
                {!isLoading && noMatchup && (
                  <h3 className={classes.contenth}>
                    No Matchup Generated. You might not have enough players{" "}
                  </h3>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </DragDropContext>
    </Layout>
  );
}

export default MatchupPage;
