import { Card, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import processToken from "../components/membership/processToken";
import SnackError from "../components/Signups/SnackError";
import SnackSuccess from "../components/Signups/SnackSuccess";
import SignInCard from "src/components/membership/SignInCard";
import Grid from "@mui/material/Grid2";
import BackgroundParallax from "src/components/parallax/BackgroundParallax";
import { ParallaxProvider } from "react-scroll-parallax";
import useIsMobileDisplay from "src/components/membership/useIsMobileDisplay";

function GoogleLoginPage({
  devPort,
  userName,
  setUserName,
  setPlayerBoard,
  newSignUp,
  setCurrentMembership,
  alertMessageSuccess,
  setAlertMessageSuccess,
  betaMode,
}) {
  //const betaMode=false
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const appleSignInCode = searchParams.get("code");
  const [isMobileDisplay] = useIsMobileDisplay();
  console.log("HERE");
  console.log(userName);
  useEffect(() => {
    if (appleSignInCode) {
      const apple_query = newSignUp ? "account-signup" : "account-login";
      processToken(
        appleSignInCode,
        "get_apple_id/" + apple_query + "/",
        devPort,
        setUserName
      );
    }
  }, [appleSignInCode, devPort, newSignUp, setUserName]);
  const isUserSubscribed = useCallback(() => {
    console.log(userName);
    console.log("Processing name");
    //TODO user Stripe user ID
    //fetch(devPort + "is-subscriber/" + userName)
    fetch(devPort + "system_get_manager/" + userName)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0][0]) {
          //.data && data.data.length != 0) {
          setCurrentMembership("manager");
          if (newSignUp) {
            console.log("ACCOUNT ALREADY EXISTS");
            setSnackMessage(signupError);
            setAlertMessage(true);
          } else {
            console.log("ACCOUNT FOUND");
            navigate("/club");
          }
          //setHasAccount(true);
        } else {
          const fetchUserId = devPort + "system_get_player/" + userName;
          fetch(fetchUserId)
            .then((idResponse) => {
              return idResponse.json();
            })
            .then((idData) => {
              if (newSignUp) {
                if (idData && idData[0][0]) {
                  setCurrentMembership("player");
                  console.log("ACCOUNT ALREADY EXISTS");
                  setSnackMessage(signupError);
                  setAlertMessage(true);
                } else {
                  navigate("/selectmembership");
                }
              } else {
                if (idData && idData[0][0]) {
                  console.log("Player Account Found");
                  setCurrentMembership("player");
                  //TODO UPDATE TO PLAYER CLUB
                  navigate("/playerclubselection");
                } else {
                  console.log("NO ACCOUNT FOUND");
                  setSnackMessage(loginError);
                  //setUserName("");
                  setCurrentMembership("");
                  setAlertMessage(true);
                  navigate("/selectmembership");
                }
              }
            });
        }
      });
  }, [devPort, navigate, newSignUp, setCurrentMembership, userName]);

  const firstUpdate = useRef(true);
  console.log(userName);
  useEffect(() => {
    console.log("RESETTING USERNAME")
    if (firstUpdate.current) {
      firstUpdate.current = false;
      console.log("IN HERE")
      setUserName("");
      setPlayerBoard(-1);
      setCurrentMembership("");
      return;
    }
    if (userName) {
      isUserSubscribed();
    }
  }, [
    userName,
    newSignUp,
    setUserName,
    setCurrentMembership,
    isUserSubscribed,
    setPlayerBoard,
  ]);
  const [alterMessage, setAlertMessage] = useState(false);
  const loginError =
    "No account found. Use another account or signup for a new account";
  const signupError = "Account already exists! Go to the login page instead";
  const [snackMessage, setSnackMessage] = useState(loginError);
  const [snackMessageSuccess, setSnackMessageSuccess] = useState("Email has been successfully reset");

  return (
    <Layout managerMode={-2} devPort={devPort}>
      <SnackError
        alterMessage={alterMessage}
        setAlertMessage={setAlertMessage}
        snackMessage={snackMessage}
      />
      <SnackSuccess
        alterMessage={alertMessageSuccess}
        setAlertMessage={setAlertMessageSuccess}
        snackMessage={snackMessageSuccess}
      />
      <Card>
        <CardContent>
          <Stack spacing={6}>
            <Stack spacing={3} textAlign="center">
              {isMobileDisplay && (
                <SignInCard
                  devPort={devPort}
                  userName={userName}
                  setUserName={setUserName}
                  setPlayerBoard={setPlayerBoard}
                  newSignUp={newSignUp}
                  setCurrentMembership={setCurrentMembership}
                  alertMessageSuccess={alertMessageSuccess}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                  setAlertMessage={setAlertMessage}
                  setSnackMessage={setSnackMessage}
                  setSnackMessageSuccess={setSnackMessageSuccess}
                  signupError={signupError}
                  betaMode={betaMode}
                />
              )}
              {!isMobileDisplay && (
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6 }}>
                    <Grid size={{ xs: 8 }} offset={{ xs: 2 }}>
                      <SignInCard
                        devPort={devPort}
                        setUserName={setUserName}
                        newSignUp={newSignUp}
                        alertMessageSuccess={alertMessageSuccess}
                        setAlertMessage={setAlertMessage}
                        setSnackMessage={setSnackMessage}
                        setAlertMessageSuccess={setAlertMessageSuccess}
                        setSnackMessageSuccess={setSnackMessageSuccess}
                        signupError={signupError}
                        betaMode={betaMode}
                      />
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <ParallaxProvider>
                      <BackgroundParallax />
                    </ParallaxProvider>
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default GoogleLoginPage;
