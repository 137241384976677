import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
interface pricingDetailInterface {
  Details: string;
  "Current Subscription": string;
  "New Subscription": string;
}
function SubscriptionTable({
  devPort,
  clubId,
  newNumberPlayer,
  newPricePerPlayer,
  subscriptionMode,
  setSubscriptionMode,
  setClubId,
}: {
  devPort: string;
  clubId: string;
  newNumberPlayer: number | null;
  newPricePerPlayer: number | null;
  subscriptionMode: "create" | "modify" | "view";
  setSubscriptionMode: any;
  setClubId: any;
}) {
  let teamColor = "#5D8AA8";
  const [currentNumberPlayer, setCurrentNumberPlayer] = useState<number>(0);
  const [currentPricePerPlayer, setCurrentPricePerPlayer] = useState<number>(0);
  let navigate = useNavigate();
  const getSubscriptionDetails = useCallback(() => {
    if (subscriptionMode !== "create") {
      fetch(devPort + "get-subscription-details/" + clubId)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("SUB DETAIL:S");
          console.log(data);
          if (data && data.length > 1) {
            setCurrentPricePerPlayer(data[0] / 100);
            setCurrentNumberPlayer(data[1]);
          }
        });
    }
  }, [devPort, clubId, subscriptionMode]);

  useEffect(() => {
    getSubscriptionDetails();
  }, [getSubscriptionDetails]);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const [columns, setColumns] = useState<string[]>();
  useEffect(() => {
    if (subscriptionMode === "create") {
      setColumns(["Details", "New Subscription"]);
    } else {
      if (subscriptionMode === "view") {
        setColumns(["Details", "Current Subscription"]);
      } else {
        setColumns(["Details", "Current Subscription", "New Subscription"]);
      }
    }
  }, [subscriptionMode]);
  const [pricingDetails, setPricingDetails] =
    useState<pricingDetailInterface[]>();
  useEffect(() => {
    if (
      subscriptionMode === "create" ||
      (currentNumberPlayer && typeof currentPricePerPlayer === "number")
    ) {
      let newPricing = "$0";
      if (newNumberPlayer && newPricePerPlayer) {
        newPricing = (newPricePerPlayer * newNumberPlayer)
          .toFixed(2)
          .toString();
      } else if (newPricePerPlayer) {
        newPricing = "$" + newPricePerPlayer?.toString();
      }
      let newPricingDetails = [
        {
          Details: "Price per player",
          "Current Subscription": "$" + currentPricePerPlayer,
          "New Subscription": newPricePerPlayer ? "$" + newPricePerPlayer : "-",
        },
        {
          Details: "Number of player",
          "Current Subscription": currentNumberPlayer?.toString(),
          "New Subscription": newNumberPlayer
            ? newNumberPlayer.toString()
            : "-",
        },
        {
          Details: "Monthly Price",
          "Current Subscription":
            "$" +
            (
              currentPricePerPlayer *
              (currentNumberPlayer ? currentNumberPlayer : 1)
            )
              .toFixed(2)
              .toString(),
          "New Subscription": newPricing,
        },
      ];
      setPricingDetails(newPricingDetails);
    }
  }, [
    newNumberPlayer,
    newPricePerPlayer,
    currentNumberPlayer,
    currentPricePerPlayer,
    subscriptionMode,
  ]);
  if (!pricingDetails || !columns) {
    return null;
  }
  return (
    <Stack gap={2}>
      <TableContainer component={Paper} data-testid={"table-subscription"}>
        <Table stickyHeader arial-label="subscription table">
          <TableHead>
            <TableRow style={{ position: "sticky", zIndex: 800 }}>
              {columns.map((column, index) => {
                console.log(column);
                return (
                  <TableCell
                    align="center"
                    style={{
                      left: index === 0 ? 0 : "",
                      background: teamColor,
                      color: "white",
                      zIndex: index === 0 ? 800 : "",
                      position: index === 0 ? "sticky" : undefined,
                    }}
                    width="200px"
                  >
                    {column}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {pricingDetails.map((row: any, signupIndex: number) => {
              return (
                <StyledTableRow key={signupIndex}>
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          left: index === 0 ? 0 : "",
                          zIndex: index === 0 ? 600 : "",
                          position: index === 0 ? "sticky" : undefined,
                          backgroundColor: index === 0 ? teamColor : "",
                          color: index === 0 ? "white" : "",
                        }}
                      >
                        {row[column]}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {subscriptionMode === "view" && (
        <Button
          variant="outlined"
          color="secondary"
          data-testid="membership-upgrade"
          onClick={() => {
            setSubscriptionMode("modify");
            setClubId(clubId);
            navigate("/managersubscription");
          }}
        >
          Update Membership
        </Button>
      )}
    </Stack>
  );
}

export default SubscriptionTable;
