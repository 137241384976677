import { Alert, Button, Stack } from "@mui/material";
import ConfirmationModal from "../ui/ConfirmationModal";
import { useState } from "react";

function ExpiredMembershipBanners({
  devPort,
  clubId,
  expiredSubscription,
  subscriptionEndDate,
  setSubscriptionEndDate,
}: {
  devPort: string;
  clubId: string;
  expiredSubscription: boolean;
  subscriptionEndDate: string;
  userName: string;
  setSubscriptionEndDate: React.Dispatch<React.SetStateAction<string>>;
}) {
  function renewSubscription() {
    let fetchCheckout = "resume-subscription";
    fetch(devPort + fetchCheckout, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clubId: clubId,
        website: window.location.href.split("#")[0], //"http://localhost:3000"
      }),
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setSubscriptionEndDate("");
        /*if (data && data.url) {
          window.location.href = data.url;
        } else {
          navigate("/club");
        }*/
      });
  }
  const [confirmationModal, setConfirmationModal] = useState(false);
  return (
    <Stack>
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        confirmationString={
          "Are you sure you want to resume your subscription? You will be charged your current monthly fee on " +
          subscriptionEndDate
        }
        onApproval={renewSubscription}
      />
      {expiredSubscription && (
        <Alert severity="error">
          Your subscription has expired. Please contact the Chukkerbook team to
          renew your subscription
        </Alert>
      )}
      {subscriptionEndDate && !expiredSubscription && (
        <Stack>
          <Alert
            severity="warning"
            //sx={{ color: "black", bgcolor: chukkerbookTheme["warning"] }}
          >
            {"Your subscription will expire on " + subscriptionEndDate}
          </Alert>
          <Button
            color="warning"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => setConfirmationModal(true)}
          >
            Resume Subscription
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default ExpiredMembershipBanners;
