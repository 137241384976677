import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Modal,
  Snackbar,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import useRefresh from "../components/Refresh/refresh";
// import getProMapping from "../components/Signups/getProMapping";
import UserWaitlist from "../components/membership/UserWaitlist";
import ClubPreviewCard from "../components/WeekAtAGlance/ClubPreviewCard";
import generateMembership from "../components/membership/generateMembership";
import BannerMessage from "../components/Signups/PriceBanner";
import ExpiredMembershipBanners from "src/components/membership/ExpiredMembershipBanners";

function ClubPage({
  managerMode,
  devPort,
  clubId,
  setClubId,
  setPlayerBoard,
  userName,
  userEmail,
  betaMode,
  setLessonPro,
  setLessonPrice,
  setEventId,
  setLeasePrice,
  setGameDate,
  setSubscriptionMode,
  setGamePoloType,
}) {
  const [alterMessage, setAlertMessage] = useState(false);
  const [clubPlayers, setClubPlayers] = useState();
  const [waitlistClubPlayers, setWaitlistClubPlayers] = useState();
  const [registerModal, setRegisterModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState();
  // const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [loadingId, setLoadingId] = useState(true);
  const [hasClubAccess, setHasClubAccess] = useState(false);
  const [needsMembershipUpgrade, setNeedsMembershipUpgrade] = useState(false);
  const [subscribeClub, setSubscribeClub] = useState(false);
  const [reloadMembership, setReloadMembership] = useState(false);
  const [clubsSubscription, setClubsSubscription] = useState();
  const [expiredSubscription, setExpiredSubscription] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();
  let navigate = useNavigate();
  useRefresh(userName, devPort);
  //var userName = 'peteymcgees' //'jeremalex1' //'peteymcgees'
  /*if(window.reactPress){
        userName=window.reactPress.usermeta.nickname;
    }*/
  //const userName = window.reactPress.usermeta.nickname
  //Reinit club when access page
  useEffect(() => {
    setClubId(-1);
    setSubscribeClub(false);
    setHasClubAccess(false);
    setNeedsMembershipUpgrade(false);
  }, [setClubId]);

  const firstUpdateClub = useRef(true);
  useEffect(() => {
    if (clubPlayers) {
      let clubIds = "";
      clubPlayers.map((club) => {
        if (clubIds) {
          clubIds += "," + club.value;
        } else {
          clubIds = club.value;
        }
        return null;
      });
      const subscription_fetch = devPort + "get_clubs_subscription/" + clubIds;
      console.log(subscription_fetch);
      fetch(subscription_fetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data) {
            setClubsSubscription(data);
          }
        });
    }
  }, [devPort, clubPlayers]);
  useEffect(() => {
    if (firstUpdateClub.current) {
      firstUpdateClub.current = false;
      return;
    }
    if (clubId !== -1) {
      if (managerMode === -1) {
        console.log("Checking restrictions...");
        const subscription_fetch = devPort + "get_club_subscription/" + clubId;
        fetch(subscription_fetch)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            if (data && "id" in data) {
              console.log("Subscription");
              console.log(data);
              if (
                "subscription_end_date" in data &&
                data["subscription_end_date"]
              ) {
                setSubscriptionEndDate(data["subscription_end_date"]);
              } else {
                setSubscriptionEndDate(null);
              }
              if (
                "subscription_end_date" in data &&
                data["subscription_end_date"] &&
                new Date(data["subscription_end_date"]).toISOString() <
                  new Date().toISOString()
              ) {
                console.log("Subscription has ended");
                setExpiredSubscription(true);
              } else {
                console.log("Subscription is active");
                console.log(data["subscription_end_date"]);
                console.log(new Date().toISOString().split("T")[0]);
                setExpiredSubscription(false);
              }
              if (
                "allowed_players" in data &&
                "max_allowed_players" in data &&
                data["allowed_players"] > data["max_allowed_players"]
              ) {
                setNeedsMembershipUpgrade(true);
              } else {
                setHasClubAccess(true);
              }
            } else {
              console.log("Would you like to subscribe");
              console.log(data);
              setSubscriptionEndDate(null);
              setExpiredSubscription(false);
              setSubscribeClub(true);
            }
          });
      } else {
        //TODO verify that the user is not on the waitlist
        setHasClubAccess(true);
      }
    } else {
      console.log("Resetter");
      setSubscribeClub(false);
      setHasClubAccess(false);
      setNeedsMembershipUpgrade(false);
    }
  }, [clubId, devPort, managerMode, reloadMembership]);
  useEffect(() => {
    if (managerMode === -1) {
      //TODO update with stripe customer name
      fetch(devPort + "is-subscriber/" + userEmail)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (!data.data || data.data.length === 0) {
            console.log("No stripe membership found");
            //navigate("/selectmembership");
          }
        });
    }
  }, [devPort, managerMode, navigate, userEmail, userName]);
  const [allClubs, setAllClubs] = useState();
  const [isLoadingAllClubs, setIsLoadingAllClub] = useState(true);
  const [registerClubId, setRegisterClubId] = useState();

  useEffect(() => {
    const fetchManagerId = devPort + "system_get_manager/" + userName;
    let fetchUserId = fetchManagerId;
    if (managerMode !== -1) {
      fetchUserId = devPort + "system_get_player/" + userName;
    }
    console.log(fetchUserId);
    fetch(fetchUserId)
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data[0][0] !== null) {
          console.log("User ID " + data[0][0]);
          setUserId(data[0][0]);
          if (managerMode !== -1) {
            setPlayerBoard(data[0][0]);
          }
          setLoadingId(false);
          //loadClubs()
        } else {
          console.log("User ID has not been set");
          //Check if manager ID exists
          fetch(fetchManagerId)
            .then((response) => {
              console.log(response);
              return response.json();
            })
            .then((data) => {
              console.log(data);
              if (data && data[0][0] !== null) {
                generateMembership(devPort, userName, 0);
                setReloadMembership(!reloadMembership);
              } else {
                navigate("/selectmembership");
              }
            });
        }
      });
  }, [
    devPort,
    managerMode,
    setPlayerBoard,
    userName,
    reloadMembership,
    navigate,
  ]);

  useEffect(() => {
    var fetchAllClubs = devPort + "get_clubs/1/ALL";
    console.log(fetchAllClubs);
    fetch(fetchAllClubs)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        let newClubs = [];
        data.map((info) => {
          if (betaMode || !info[3]) {
            newClubs.push({ label: info[1] + ":" + info[2], value: info[0] });
          }
          return null;
        });
        setAllClubs(newClubs);
        setIsLoadingAllClub(false);
        console.log("All Clubs have been loaded");
      });
  }, [devPort, betaMode, reloadMembership]);
  //const [clubId, setClubId] = useState()

  const loadClubs = useCallback(() => {
    //console.log(window.reactPress)
    console.log(userId);
    const fetch_get_clubs =
      devPort +
      "get_clubs/" +
      userId +
      "/" +
      (managerMode === -1 ? "MANAGER" : "PLAYER");
    console.log(fetch_get_clubs);
    fetch(fetch_get_clubs)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);

        let newClubs = [];
        let waitlistClubs = [];
        data.map((info) => {
          //TODO update with club ID
          const clubData = { label: info[1], value: info[0] };
          if (info[3]) {
            waitlistClubs.push(clubData);
          } else {
            newClubs.push(clubData);
          }
          return null;
          //return { label: info[1], value: info[0] };
        });
        setClubPlayers(newClubs);
        setWaitlistClubPlayers(waitlistClubs);
        setIsLoading(false);
      });
  }, [devPort, managerMode, userId]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    loadClubs();
  }, [userId, loadClubs, reloadMembership]);
  function goToGames() {
    if (clubId === -1) {
      window.alert("Select a club from the dropdown menu");
    } else {
      console.log("navigating");
      if (managerMode === -1) {
        navigate("/game");
      } else {
        navigate("/playergame");
      }
    }
  }

  function resetModal() {
    setClubName("");
    setClubCountry("");
    setClubState("");
    setClubCity("");
  }

  function registerClub() {
    console.log(clubName);
    console.log(clubCountry);
    console.log(clubState);
    console.log(clubCity);
    if (!clubName || !clubCountry || !clubState || !clubCity) {
      window.alert("Fill all the required fields");
    } else {
      console.log(
        devPort +
          "add_club/" +
          userId +
          "/" +
          clubName +
          "/" +
          clubCountry +
          "/" +
          clubState +
          "/" +
          clubCity
      );
      fetch(
        devPort +
          "add_club/" +
          userId +
          "/" +
          clubName +
          "/" +
          clubCountry +
          "/" +
          clubState +
          "/" +
          clubCity
      ).then((response) => {
        setRegisterModal(false);
        setIsLoading(true);
        loadClubs();
        setAlertMessage(true);
        return response.json();
      });
    }
  }
  function registerPlayerToClub() {
    console.log(registerClubId);
    console.log(playerFirstName);
    console.log(playerLastName);
    if (!registerClubId || !playerFirstName || !playerLastName) {
      window.alert("Fill all the required fields");
    } else {
      //TODO CHECK IF CLUB HAS RESTRICTED ACCESS
      const fetch_club_access = devPort + "club_access/" + registerClubId;
      console.log(fetch_club_access);
      fetch(fetch_club_access)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("Club Access");
          if (data && data[0][0]) {
            console.log("RESTRICTED ACCESS. Joining Waitlist...");
          }
          const editPlayer =
            devPort +
            "edit_player/" +
            userId +
            "/" +
            playerFirstName +
            "/" +
            playerLastName +
            "/-2/-2/" +
            registerClubId +
            "/false/" +
            data[0][0];
          console.log(editPlayer);
          fetch(editPlayer).then((response) => {
            setRegisterModal(false);
            setIsLoading(true);
            loadClubs();
            setAlertMessage(true);
            return response.json();
          });
        });
    }
  }
  const [clubName, setClubName] = useState("");
  const [clubCountry, setClubCountry] = useState("");
  const [clubState, setClubState] = useState("");
  const [clubCity, setClubCity] = useState("");
  const [playerFirstName, setPlayerFirstName] = useState("");
  const [playerLastName, setPlayerLastName] = useState("");
  //manager_add_club(self, club_name, club_country, club_state, club_city, club_manager_id)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper", // 'red', //'background.paper',
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  //<Layout managerMode={managerMode}>
  console.log("WAITLIST");
  console.log(waitlistClubPlayers);
  console.log(clubPlayers);
  return (
    <Layout managerMode={-2} devPort={devPort}>
      <Snackbar
        open={alterMessage}
        autoHideDuration={3000}
        onClose={() => {
          setAlertMessage(false);
        }}
      >
        <Alert severity="success"> Succesfully registered the new club!</Alert>
      </Snackbar>
      <Card>
        <CardContent>
          {!isLoading && !loadingId && !isLoadingAllClubs && (
            <Stack spacing={3}>
              <h1> Welcome!</h1>
              {managerMode === -1 && <h3>Manage Your Clubs</h3>}
              {managerMode !== -1 && <h3>Select Club</h3>}
              <Autocomplete
                disablePortal
                inputProps={{ "data-testid": "list-available-clubs" }}
                data-testid="list-available-clubs"
                id="ClubName"
                options={clubPlayers}
                sx={{ width: 325 }}
                renderInput={(params) => (
                  <TextField {...params} label="Club Name" />
                )}
                onChange={(event, newValue) => {
                  //TODO set value so that correct when loading the page
                  console.log(clubPlayers);
                  console.log(newValue.value);
                  setSubscribeClub(false);
                  setHasClubAccess(false);
                  setNeedsMembershipUpgrade(false);
                  setClubId(newValue.value);
                }}
              />
              {subscribeClub && (
                <Button
                  variant="contained"
                  color="success"
                  data-testid="subscribe-club"
                  onClick={() => {
                    setSubscriptionMode("create");
                    navigate("/managersubscription");
                  }}
                >
                  {" "}
                  Subscribe to ChukkerBook for that club{" "}
                </Button>
              )}
              {needsMembershipUpgrade && (
                <BannerMessage
                  message="You have reached the maximum number of players for that club. You will need to upgrade your memebership"
                  color="secondary"
                />
              )}
              {needsMembershipUpgrade && (
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="manager-players"
                  onClick={() => {
                    navigate("/player");
                  }}
                >
                  {" "}
                  Manage Player List{" "}
                </Button>
              )}
              {needsMembershipUpgrade && (
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="membership-upgrade"
                  onClick={() => {
                    setSubscriptionMode("modify");
                    navigate("/managersubscription");
                  }}
                >
                  {" "}
                  Upgrade your ChukkerBook membership for that club{" "}
                </Button>
              )}
              <ExpiredMembershipBanners
                devPort={devPort}
                clubId={clubId}
                expiredSubscription={expiredSubscription}
                subscriptionEndDate={subscriptionEndDate}
                setSubscriptionEndDate={setSubscriptionEndDate}
              />
              <Button
                variant="contained"
                color="primary"
                data-testid="upcoming-game-button"
                onClick={goToGames}
                disabled={!hasClubAccess || expiredSubscription}
              >
                {" "}
                Go to club page{" "}
              </Button>
              {managerMode === -1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="register-club-button"
                  onClick={() => {
                    resetModal();
                    setRegisterModal(true);
                  }}
                >
                  {" "}
                  Register New Club
                </Button>
              )}
              {managerMode !== -1 && (
                <Button
                  variant="contained"
                  color="secondary"
                  data-testid="join-club-button"
                  onClick={() => {
                    resetModal();
                    setRegisterModal(true);
                  }}
                >
                  {" "}
                  Join New Club
                </Button>
              )}
              <h3>Your Week At A Glance</h3>
              {clubsSubscription &&
                clubPlayers.map((club, index) => {
                  //<h3>{club.label}</h3>;
                  //setClubId(club.value);
                  if (
                    managerMode !== -1 ||
                    (club.value in clubsSubscription &&
                      clubsSubscription[club.value][2] &&
                      clubsSubscription[club.value][4])
                  ) {
                    return (
                      <ClubPreviewCard
                        club={club}
                        key={index}
                        managerMode={managerMode}
                        devPort={devPort}
                        setLessonPro={setLessonPro}
                        setLessonPrice={setLessonPrice}
                        setEventId={setEventId}
                        setClubId={setClubId}
                        setLeasePrice={setLeasePrice}
                        setGameDate={setGameDate}
                        setGamePoloType={setGamePoloType}
                      />
                    );
                  }
                  return null;
                })}
              <UserWaitlist
                devPort={devPort}
                userList={
                  managerMode === -1 ? clubPlayers : waitlistClubPlayers
                }
                managerMode={managerMode}
              />
            </Stack>
          )}
          {(isLoading || loadingId || isLoadingAllClubs) && (
            <LinearProgress color="secondary" />
          )}
          <Modal
            data-testid="register-club-modal"
            open={registerModal}
            onClose={() => {
              setRegisterModal(false);
            }}
            aria-labelledby="Club Registration"
            aria-describedby="Register Club"
          >
            <Box sx={style}>
              <Stack spacing={2}>
                {managerMode === -1 && (
                  <Stack spacing={2}>
                    <TextField
                      inputProps={{ "data-testid": "club-name" }}
                      id="club-name"
                      label="Club Name"
                      variant="filled"
                      onChange={(event) => {
                        console.log(event.target.value);
                        setClubName(event.target.value);
                      }}
                    />
                    <TextField
                      inputProps={{ "data-testid": "club-country" }}
                      id="club-country"
                      label="Country"
                      variant="filled"
                      onChange={(event) => {
                        setClubCountry(event.target.value);
                      }}
                    />
                    <TextField
                      inputProps={{ "data-testid": "club-state" }}
                      id="club-state"
                      label="State/Province/Region"
                      variant="filled"
                      onChange={(event) => {
                        setClubState(event.target.value);
                      }}
                    />
                    <TextField
                      inputProps={{ "data-testid": "club-city" }}
                      id="club-city"
                      label="City"
                      variant="filled"
                      onChange={(event) => {
                        setClubCity(event.target.value);
                      }}
                    />
                  </Stack>
                )}
                {managerMode !== -1 && (
                  <Stack spacing={2}>
                    <Autocomplete
                      disablePortal
                      data-testid="modal-club-name"
                      id="ClubName"
                      options={allClubs}
                      sx={{ width: 325 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Club Name" />
                      )}
                      onChange={(event, newValue) => {
                        console.log(newValue.value);
                        setRegisterClubId(newValue.value);
                      }}
                    />
                    <TextField
                      inputProps={{ "data-testid": "first-name" }}
                      id="first-name"
                      label="First Name"
                      variant="filled"
                      onChange={(event) => {
                        setPlayerFirstName(event.target.value);
                      }}
                    />
                    <TextField
                      inputProps={{ "data-testid": "last-name" }}
                      id="last-name"
                      label="Last Name"
                      variant="filled"
                      onChange={(event) => {
                        setPlayerLastName(event.target.value);
                      }}
                    />
                  </Stack>
                )}
                <Stack direction="row" spacing={2}>
                  <Button
                    data-testid="modal-register"
                    variant="contained"
                    color="success"
                    onClick={
                      managerMode === -1 ? registerClub : registerPlayerToClub
                    }
                  >
                    Register
                  </Button>
                  <Button
                    data-testid="modal-close"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setRegisterModal(false);
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
        </CardContent>
      </Card>
    </Layout>
  );
}
export default ClubPage;
