import { Card, CardContent, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

function EmptyCard() {
  //let teamColor = { back: chukkerbookTheme["secondary"], text: "white" }; // "#A2D69A"; // '#5D8AA8';
  return (
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  //justify="center"
  //style={{ minHeight: '100vh' }}
 >
    <Paper elevation={0} style={{width: "60%"}}>
      <Card
        variant="outlined"
        sx={{ mx: -3 }}
        style={{
          textTransform: 'none',
          border: `2px dashed`,
          borderRadius: '16px',
          color: "grey"
        }}
        //style={{ backgroundColor: teamColor["back"] }}
      >
        <CardContent>
          <Typography /*color={teamColor["text"]}*/>
            Drag and Drop a player to that team
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  </Grid>
  );
}

export default EmptyCard;
