import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
function NoEmailModal({
  navigateModal,
  setNavigateModal,
}: {
  navigateModal: boolean;
  setNavigateModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={navigateModal}
      onClose={() => {
        setNavigateModal(false);
      }}
      aria-labelledby="no-mailing-list"
      aria-describedby="No mailing list"
      data-testid="no-mailing-list-modal"
    >
      <Box sx={style}>
        <Typography variant="h4">
          You have not set up your mailing list yet
        </Typography>
        <Typography variant="body1">
          Please set it in the Settings section
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              data-testid="go-to-settings"
              style={{ width: "100%" }}
              onClick={() => {
                setNavigateModal(false);
                navigate("/user-settings");
              }}
            >
              Go To Settings
            </Button>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="error"
              data-testid="save-modal-no"
              style={{ width: "100%" }}
              onClick={() => {
                setNavigateModal(false);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default NoEmailModal;
