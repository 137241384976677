import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import PaidIcon from '@mui/icons-material/Paid';
import InsightsIcon from '@mui/icons-material/Insights';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SportsIcon from '@mui/icons-material/Sports';

import MobileAnalytics from "./MobileAnalytics.png"
import MobileLessons from "./MobileLessons.png"
import MobileMatchups from "./MobileMatchups.png"
import MobilePayment from "./MobilePayment.png"

const logoStyle = {
  //width: '100px',
  height: "350px",
  margin: "0 32px",
  opacity: 1.0,
};

const logoStyleDesktop = {
  //width: '100px',
  height: "700px",
  margin: "0 32px",
  opacity: 1.0,
};

const items = [
  {
    icon: <InsertInvitationIcon />,
    title: "Scheduling",
    description:
      "No more juggling texts, email, and in-person requests. You create the schedule, and players can log in and sign up in minutes.",
    imageLight: MobileLessons,
  },
  {
    icon: <InsightsIcon />,
    title: "Analytics",
    description:
      "Track membership numbers, chukker signups, and more throughout the season with the analytics dashboard.",
    imageLight: MobileAnalytics,
  },
  {
    icon: <PaidIcon />,
    title: "Payments",
    description:
      "Set pricing for lessons, horse leases, and membership packages, and players can seamlessly pay online using Stripe. Payments can be directed to the club manager or to designated club pros and coaches.",
    imageLight: MobilePayment,
  },
  {
    icon: <SportsIcon />,
    title: "Matchups",
    description:
    "Automatically generate chukkers. Choose between prioritizing handicap-balanced teams or fulfilling players' pro requests. Tweak the line-ups with the drag-and-drop interface.",
    imageLight: MobileMatchups,
  },
];

interface ChipProps {
  selected?: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: theme.palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)",
        },
        ...theme.applyStyles("dark", {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

interface MobileLayoutProps {
  selectedItemIndex: number;
  handleItemClick: (index: number) => void;
  selectedFeature: (typeof items)[0];
  setBetaMode: React.Dispatch<React.SetStateAction<boolean>>
}

export function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
  setBetaMode
}: MobileLayoutProps) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, overflow: "auto" }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            //selected={selectedItemIndex === index}
            color={selectedItemIndex === index ? "secondary" : undefined}
          />
        ))}
      </Box>
      {/*<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedItemIndex}
          onChange={(event, newValue) => {
            console.log(newValue);
            handleItemClick(newValue);
          }}
          textColor="secondary"
          indicatorColor="secondary"
          variant="fullWidth"
        >
          {items.map(({ title }, index) => (
          <Tab label={title} value={index} wrapped />
          ))}
        </Tabs>
          </Box>*/}
      <img
        src={items[selectedItemIndex].imageLight}
        //alt={`Fake company number ${index + 1}`}
        style={logoStyle}
        onDoubleClick={() => setBetaMode(true)}
        alt="Mobile feature"
      />
        {/*<Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: 280,
            backgroundImage: "var(--items-imageLight)",
            ...theme.applyStyles("dark", {
              backgroundImage: "var(--items-imageDark)",
            }),
          })}
          style={
            items[selectedItemIndex]
              ? ({
                  "--items-imageLight": items[selectedItemIndex].imageLight,
                  "--items-imageDark": items[selectedItemIndex].imageDark,
                } as any)
              : {}
          }
        />*/}
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "white", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "white", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
    </Box>
  );
}

export default function Features({setBetaMode}: {setBetaMode: React.Dispatch<React.SetStateAction<boolean>>}) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ width: { sm: "100%", md: "60%" } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "white" }}
        >
          Everything In One Place
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "white", mb: { xs: 2, sm: 4 } }}
        >
          Learn how Chukkerbook can help your club run more smoothly by
          consolidating signups, analytics, member management and more in one
          place.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: "100%",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.action
                        .hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: "action.selected",
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: 1,
                      textAlign: "left",
                      textTransform: "none",
                      color: "black",
                    },
                    selectedItemIndex === index && {
                      color: "white",
                    },
                  ]}
                >
                  {icon}

                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
            setBetaMode={setBetaMode}
          />
        </div>
        <Box
          sx={
            {
              display: { xs: 'none', sm: 'flex' },
              width: { xs: '100%', md: '70%' },
              height: 'var(--items-image-height)',
            }
          }
        >
          <img
            src={items[selectedItemIndex].imageLight}
            //alt={`Fake company number ${index + 1}`}
            style={logoStyleDesktop}
            onDoubleClick={() => setBetaMode(true)}
            alt="Mobile feature"
          />
          {/*<Box
              sx={(theme) => ({
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={
                items[selectedItemIndex]
                  ? ({
                      '--items-imageLight': items[selectedItemIndex].imageLight,
                      '--items-imageDark': items[selectedItemIndex].imageDark,
                    } as any)
                  : {}
              }
            />*/}
        </Box>
      </Box>
    </Container>
  );
}
