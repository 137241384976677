import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Modal,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
type clauseInterface = [string, string[]];
function LegalDisclaimerModal({
  devPort,
  legalModal,
  setLegalModal,
  legalDocument,
  OnModalClose,
}: {
  devPort: string;
  legalModal: boolean;
  setLegalModal: Dispatch<SetStateAction<boolean>>;
  legalDocument: "get_terms_of_use" | "get_privacy_policy";
  OnModalClose: () => void;
}) {
  const style = {
    position: "absolute",
    //top: '50%',
    //left: '50%',
    //transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll'
  };
  const modalStyle1 = {
    position: "absolute",
    top: "5%",
    left: "5%",
    right: "5%",
    bottom: "5%",
    overflow: "scroll",
    WebkitOverflowScrolling: "touch",
    height: "100%",
    display: "block",
  };
  const [termsAndConditions, setTermsAndConditions] =
    useState<clauseInterface[]>();
  useEffect(() => {
    const termsOfUseFetch = devPort + legalDocument;
    console.log(termsOfUseFetch);
    fetch(termsOfUseFetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          console.log("Terms And Conditions");
          console.log(data);
          if (data && data.length !== 0) {
            console.log(data);
            setTermsAndConditions(data);
          }
        }
      });
  }, [devPort, legalDocument]);

  return (
    <Modal
      open={legalModal}
      onClose={() => {
        OnModalClose();
        setLegalModal(false);
      }}
      aria-labelledby="Legal Modal"
      aria-describedby="Legal Modal"
      sx={modalStyle1}
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <Stack>
              {!termsAndConditions && <LinearProgress color="secondary" />}
              {termsAndConditions &&
                termsAndConditions.map(
                  (clause: clauseInterface, clauseIndex: number) => {
                    return (
                      <Stack key={clauseIndex}>
                        <Typography variant="h6">{clause[0]}</Typography>
                        {clause[1].map((line: string, index: number) => {
                          return (
                            <Typography
                              variant="body1"
                              style={{ whiteSpace: "pre-line" }}
                              key={index}
                            >
                              {line}
                            </Typography>
                          );
                        })}
                      </Stack>
                    );
                  }
                )}
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default LegalDisclaimerModal;
