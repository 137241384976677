import { Tooltip } from "@mui/material";

export default function ProRequest({ requestedPro, proColorMapping, proMapping }) {
  if (requestedPro && proColorMapping && proColorMapping[requestedPro] && proMapping && proMapping[requestedPro]) {
    return (
      <Tooltip title={proMapping[requestedPro]}>
        <img
          src={proColorMapping[requestedPro][1]}
          alt="Polo Pro Color"
          width={30}
          height={30}
          sx={{
            display: { xs: "none", md: "flex" },
            mr: 1,
          }}
        />
      </Tooltip>
    );
  }
  return "-";
}
