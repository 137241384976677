 const chukkerbookTheme = {
  primary: "#5D8AA8",
  secondary: "#800020",
  success: "#A2D69A",
  warning: "#F5C242"
};
export const chukkerbookThemeTransparent = {
  primary: "rgba(93, 138, 168, 0.10)",
  secondary: "rgba(128, 0, 32, 0.10)",
  success: "rgba(162, 214, 154, 0.10)",
  warning: "rgba(245, 194, 66, 0.10)"
};
export type colorType = "primary" | "secondary" | "success" | "warning";
export const colors:colorType[] =["warning", "primary", "success", "secondary"]

export default chukkerbookTheme;
