import { Card, CardContent, Paper, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import chukkerbookTheme from "../../chukkerbbokTheme";
import Grid from "@mui/material/Grid2";
import ProRequest from "../Signups/ProRequest";

function PlayerItem(props) {
  let teamColor = {
    back: chukkerbookTheme["success"],
    text: "black",
  }; // "#A2D69A"; // '#5D8AA8';
  if (props.team.match("A_")) {
    if (props.isBench) {
      teamColor = { back: chukkerbookTheme["secondary"], text: "black" }; // "#5D8AA8"; //'#800020';
    } else {
      teamColor = { back: chukkerbookTheme["warning"], text: "black" }; // "#5D8AA8"; //'#800020';
    }
  }
  return (
    <Draggable
      draggableId={props.id}
      isDragDisabled={props.managerMode !== -1}
      index={parseInt(props.id)}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-testid={"player-" + props.id}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            //justify="center"
            //style={{ minHeight: '100vh' }}
          >
            <Paper elevation={24} style={{ width: "60%" }}>
              <Card
                variant="outlined"
                sx={{ mx: -3 }}
                //style={{ backgroundColor: teamColor["back"] }}
                style={{
                  textTransform: "none",
                  //border: `2px dashed`,
                  borderRadius: "16px",
                  color: "grey",
                  backgroundColor:
                    props.proColorMapping &&
                    props.proColorMapping["PROS"] &&
                    props.proColorMapping["PROS"][props.index]
                      ? props.proColorMapping["PROS"][props.index][0]
                      : undefined, //teamColor["back"]
                }}
              >
                <CardContent>
                  <Typography color={teamColor["text"]}>
                    {props.firstName +
                      " " +
                      props.lastName +
                      " " +
                      ((props.managerMode !== -1 && ! props.betaMode) ? "" : props.rating)}
                  </Typography>
                  {props.proColorMapping &&
                    props.proColorMapping["PLAYERS"] &&
                    props.proColorMapping["PLAYERS"][props.index] && (
                      <ProRequest
                        requestedPro={
                          props.proColorMapping["PLAYERS"][props.index]
                        }
                        proColorMapping={props.proColorMapping["PROS"]}
                        proMapping={props.proColorMapping["MAPPING"]}
                      />
                    )}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </div>
      )}
    </Draggable>
  );
}

export default PlayerItem;
