import {
  Stack,
  Card,
  CardContent,
  Typography,
  Fab,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import GrassIcon from "@mui/icons-material/Grass";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";

function IndividualGame({
  game,
  setRegisterDate,
  setRegisterStartTime,
  setRegisterField,
  setRegisterManagerNotes,
  setRegisterGameType,
  setSelectedPrice,
  setRecipientStripeId,
  setNumberHorseChukker,
  setPotentialDeleteGameId,
  setUpdateModal,
  managerMode,
  setEventId,
  clubId,
  setClubId,
  setLeasePrice,
  setGameDate,
  setGamePoloType
}) {
  let navigate = useNavigate();
  //<Typography color="secondary" variant="h5">{game.event_type}</Typography>
  const theme = useTheme();
  const stylers = {
    backgroundColor: "white",
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
  };
  console.log("CHECK THAT GAME", game)
  return (
    <Card
      variant="outlined"
      //style={{ borderColor: "#5D8AA8", borderWidth: "medium", backgroundColor: "rgba(93, 138, 168, 0.55)" }}
      style={{ backgroundColor: "rgba(93, 138, 168, 0.10)" }}
    >
      <CardContent>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <SportsScoreIcon color="primary" />
            <Typography variant="body1" color="primary">
              Game
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={4}
              style={{ marginRight: 0, marginLeft: "auto" }}
            >
              {managerMode === -1 && !game.event_type && (
                <Fab
                  style={stylers}
                  //color="primary"
                  size="small"
                  onClick={() => {
                    setRegisterDate(game.game_date);
                    setRegisterStartTime(game.game_date);
                    setRegisterField(game.field_location);
                    setRegisterManagerNotes(game.manager_notes);
                    setRegisterGameType(game.game_type);
                    setSelectedPrice(game.lease_price);
                    setRecipientStripeId(game.lease_recipient);
                    setNumberHorseChukker(game.number_horse_chukker);
                    setPotentialDeleteGameId(game.game_id);
                    setUpdateModal(true);
                  }}
                  fullWidth={true}
                >
                  <EditIcon />
                </Fab>
              )}
              <Fab
                variant="contained"
                size="small"
                onClick={() => {
                  if (setClubId) {
                    setClubId(clubId);
                  }
                  setEventId(game.id);
                  setGameDate(game.date);
                  setLeasePrice(game.lessonPrice);
                  setGamePoloType(game.game_type);
                  //if (managerMode === -1) {
                  navigate("/playersignup");
                  //} else {
                  //  navigate("/playergamesignup");
                  //}
                }}
                color="success"
                //disabled={game.available === 0}
                fullWidth={true}
              >
                <VisibilityIcon />
              </Fab>
            </Stack>
          </Stack>
          <Stack gap={1}>
            <Stack direction="row">
              <Typography color="primary">{"Time"}</Typography>
              <Typography style={{ marginRight: 0, marginLeft: "auto" }}>
                {game.time}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography color="primary">{"Location"}</Typography>
              <Typography style={{ marginRight: 0, marginLeft: "auto" }}>
                {game.field_location}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography color="primary">{"Details"}</Typography>
              <Typography style={{ marginRight: 0, marginLeft: "auto" }}>
                {game.manager_notes}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography color="primary">{"Type"}</Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                style={{ marginRight: 0, marginLeft: "auto" }}
              >
                {game.game_type === "ARENA" && (
                  <WarehouseIcon
                    color="action"
                    style={{ marginRight: 0, marginLeft: "auto" }}
                  />
                )}
                {game.game_type === "OUTDOOR" && (
                  <GrassIcon
                    color="success"
                    style={{ marginRight: 0, marginLeft: "auto" }}
                  />
                )}
                <Typography
                  variant="body1"
                  style={{ marginRight: 0, marginLeft: "auto" }}
                >
                  {game.game_type}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default IndividualGame;
